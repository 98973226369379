/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Many classical machine learning methods re-express their input data in a\ndifferent space to make it easier to work with, or because the new space may\nhave some convenient properties. A common example is ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Support_vector_machine"
  }, "support vector machines"), ", which classify data\nusing a linear hyperplane. A linear hyperplane works well when the data is\nalready linearly separable in the original space, however this is unlikely to be\ntrue for many data sets. To work around this it may be possible to transform the\ndata into a new space where it is linear by way of a ", React.createElement(_components.em, null, "feature map"), "."), "\n", React.createElement(_components.p, null, "More formally, let $\\cal{X}$ be a set of input data. A feature map\n$\\phi$ is a function that acts as $\\phi : \\cal{X} \\rightarrow\n\\cal{F}$ where $\\cal{F}$ is the feature space. The outputs of the map on\nthe individual data points, $\\phi(x)$ for all $x \\in \\cal{X}$, are\ncalled feature vectors."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/c4955fd7d55043ee9ee81012c9d68f20/feature_map_svm.svg",
    alt: "feature map svm"
  })), "\n", React.createElement("div", {
    className: "text-center mb-8"
  }, "A feature map can transform data into a space where it is easier to process."), "\n", React.createElement(_components.p, null, "In general $\\cal{F}$ is just a vector space — a ", React.createElement(_components.em, null, "quantum feature map"), "\n$\\phi : \\cal{X} \\rightarrow \\cal{F}$ is a feature map where the vector\nspace $\\cal{F}$ is a Hilbert space and the feature vectors are quantum\nstates. The map transforms $x \\rightarrow |\\phi(x)\\rangle$ by way of a\nunitary transformation $U_{\\phi}(x)$, which is typically a\n", React.createElement(_components.a, {
    href: "/qml/glossary/variational_circuit"
  }, "variational circuit"), " whose parameters\ndepend on the input data."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/9713d367b03d0bef49c4684b8a34e9ed/quantum_feature_map.svg",
    alt: "quantum feature map"
  })), "\n", React.createElement(_components.p, null, "For some more detailed examples of quantum feature maps, see the entry for\n", React.createElement(_components.a, {
    href: "/qml/glossary/quantum_embedding"
  }, "quantum embeddings"), ", and the key references ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1803.07128"
  }, "Schuld &\nKilloran (2018)"), ", and ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1804.11326"
  }, "Havlicek et al. (2018)"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
